<template>
  <div>
    <p class="filter-subtitle">
      {{ title }}
    </p>
    <div class="filter-section-container">
      <div
        v-for="(item, index) in filterList"
        class="filter-department-item"
        :class="
          mapFilter({ bool: false, id: item.id, source: ids, item: item })
        "
        :key="index"
        @click="onFilterClick({ id: item.id, title: title })"
      >
        {{
          title === 'Location'
            ? item.city
            : item[Object.keys(item).filter((x) => x.includes('Name'))[0]]
        }}
      </div>
      <div
        v-if="title === 'Location'"
        class="filter-department-item short"
        :class="mapFilter({ bool: true })"
        @click="onFilterClick({ id: 'isRemote', title: 'Location' })"
      >
        Remote
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ids: {
      type: Array,
      default: null,
    },
    statusIds: {
      type: Array,
      default: null,
    },
    locationIds: {
      type: Array,
      default: null,
    },
    departmentIds: {
      type: Array,
      default: null,
    },
    filterList: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isRemote: {
      type: Number,
      default: null,
    },
    filterStatus: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  methods: {
    /**
     * Function to determined active status
     * @param {number} id - status id
     * @param {array} source - list of selected status id's
     * @param {boolean} bool
     */
    mapFilter(param) {
      const { bool, id, source, item } = param
      if (this.type === 'employment') {
        if (this.filterStatus === id) {
          return 'filter-active-dept'
        }
      } else {
        if (this.title === 'Location') {
          if (!bool) {
            const source = this.ids
            const index = source.indexOf(id)
            if (index >= 0) {
              return 'filter-active-dept'
            }
          } else if (bool && this.isRemote) {
            return 'filter-active-dept'
          }
        } else {
          const index = source.indexOf(id)
          if (index >= 0) {
            return 'long filter-active-dept'
          } else {
            if (
              item[Object.keys(item).filter((x) => x.includes('Name'))[0]]
                .length > 15
            ) {
              return 'long'
            }
          }
        }
      }
    },

    /**
     * Emit handle click for change status filter
     * @param {number} id - for change status filter
     * @param {string} title - for change status filter
     */
    onFilterClick({ id, title }) {
      const param = {
        id,
        type: title,
      }
      this.type === 'employment'
        ? this.$emit('onFilter', this.filterStatus === id ? null : id)
        : this.$emit('onFilter', param)
    },
  },
}
</script>

<style></style>
