<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Employment">
      <template #page-header>
        <h2 class="page-title">Employee</h2>
        <r-menu-tab :level="2" />
      </template>
      <template #action-bar>
        <EmploymentHeader
          :search="true"
          :open="isFilterOpen"
          @onFiltered="isFilterOpen = !isFilterOpen"
          @onToggleFilterStatus="onToggleFilterStatus"
          @onReset="resetState"
          :loadData="isEmployeeArchiveListLoading"
          @onSearch="searchIconClick"
        />
      </template>
      <template #page-content>
        <div id="table-request-employment">
          <b-table
            :data="employeeArchiveList"
            :per-page="perPage"
            :loading="isEmployeeArchiveListLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="full_name"
                label="Employee's Name"
                sortable
                width="18%"
                v-slot="props"
              >
                <div class="columns employment-table-name">
                  <img
                    class="employment-profile-picture"
                    :src="
                      props.row
                        ? determineProfilePicture(
                            props.row.profilePicture,
                            props.row.gender
                          )
                        : ''
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.name ? props.row.name : '' }}
                  </span>
                </div>
              </b-table-column>
              <b-table-column
                field="users.work_email"
                label="Email"
                sortable
                width="12%"
                v-slot="props"
              >
                {{
                  props.row
                    ? textSubString(props.row.workEmail, '40', '...')
                    : ''
                }}
              </b-table-column>
              <b-table-column
                field="users.city"
                label="City"
                sortable
                width="15%"
                v-slot="props"
              >
                <span class="is-capitalize">
                  {{ props.row.city ? props.row.city : '' }}
                </span>
              </b-table-column>
              <b-table-column
                field="users.province"
                label="Province"
                sortable
                width="15%"
                v-slot="props"
              >
                <span class="is-capitalize">
                  {{ props.row.province ? props.row.province : '' }}
                </span>
              </b-table-column>
              <b-table-column
                field="users.annual_leave"
                label="Remaining Annual Leave"
                sortable
                width="20%"
                v-slot="props"
              >
                {{
                  props.row
                    ? formatAnnualLeave(
                        props.row.annualLeave,
                        props.row.remainingAnnualLeave
                      )
                    : ''
                }}
              </b-table-column>
              <b-table-column
                label="Action"
                width="10%"
                centered
                v-slot="props"
              >
                <b-icon
                  icon="restore"
                  custom-size="mdi-18px"
                  class="employment-all-icon"
                  @click.native="restoreWarning(props.row.userId)"
                ></b-icon>
                <!-- Delete will not be used until next sprint so don't delete this quote yet -->
                <!-- <b-icon
                  icon="delete"
                  custom-size="mdi-18px"
                  class="employment-all-icon"
                ></b-icon> -->
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmploymentHeader from '../../components/Header/EmploymentHeader.vue'

export default {
  components: {
    // GeneralFilterTableVue
    EmploymentHeader,
  },
  computed: {
    ...mapGetters({
      employeeArchiveList: 'employment/getEmployeeArchiveList',
    }),
  },
  data() {
    return {
      checkedRows: [],
      isRowCheckable: true,
      stickyHeaders: true,

      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'asc',
      sortField: 'full_name',
      sortOrder: 'asc',
      search: '',

      showDetailIcon: true,
      isEmployeeArchiveListLoading: false,
      isLoadMore: false,

      selectedEmployee: null,
    }
  },
  methods: {
    ...mapActions({
      actionLoadEmployeeArchiveList: 'employment/fetchEmployeeListArchive',
      restoreEmployee: 'employment/restoreEmployee',
    }),

    ...mapMutations({
      setEmployeeListsArchive: 'employment/setEmployeeListArchive',
    }),

    formatAnnualLeave(annualLeave, remaining) {
      let formatAnnualLeave = '-'
      let formatRemaining = '-'
      if (annualLeave) {
        formatAnnualLeave = annualLeave
      }
      if (remaining) {
        formatRemaining = remaining
      }
      return `${formatRemaining}/${formatAnnualLeave}`
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadEmployeeArchiveList()
        this.isLoadMore = false
      }
    },

    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setEmployeeListsArchive([])
      await this.loadEmployeeArchiveList(searchInput)
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    async onPageChange(page) {
      this.page = page
      await this.loadEmployeeArchiveList()
    },

    async loadEmployeeArchiveList(searchInput) {
      this.isEmployeeArchiveListLoading = true

      try {
        const params = {
          search: searchInput,
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          page: ++this.page,
        }

        const response = await this.actionLoadEmployeeArchiveList(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isEmployeeArchiveListLoading = false
    },

    // For Pop-up Restore
    async restoreWarning(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to restore this user?',
      })

      try {
        if (res && res.isConfirmed) {
          let form = {}

          // call vuex method (see employment.module.js)
          await this.restoreEmployee(id, form)

          this.$swal({
            icon: 'success',
            titleText: 'User is successfully restored',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.resetState()
        }
      } catch (e) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed. Please try again!',
          text: e.response.data.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },

    determineProfilePicture(user, gender) {
      if (!user) {
        if (gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user
    },
  },

  async mounted() {
    this.setEmployeeListsArchive([])
    await this.loadEmployeeArchiveList()
  },
}
</script>
