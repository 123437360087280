<template>
  <div>
    <b-field class="search-container">
      <b-input
        placeholder="Search"
        type="text"
        @keydown.native.enter="searchIconClick($event)"
        icon-right="magnify"
        @icon-right-click="searchIconClick($event)"
        icon-right-clickable
        :loading="loadData"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    loadData: Boolean,
  },

  methods: {
    searchIconClick(evt) {
      this.$emit('onSearch', evt.target.value)
    },
  },
}
</script>

<style></style>
