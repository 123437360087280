<template>
  <div>
    <div class="level">
      <div>
        <slot name="addBtn"></slot>
      </div>

      <div class="level-right">
        <div v-if="filter" class="level-item">
          <b-button
            icon-left="filter-variant"
            class="btn-filter-people"
            @click="filtered"
          >
            Filter
          </b-button>
        </div>

        <div class="level-item is-3">
          <!-- <slot name="search"></slot> -->
          <div v-if="search">
            <SearchFilter
              :loadData="lodaData"
              @onSearch="searchIconClick($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <FilterVue
      :pageMenu="'employment'"
      :open="open"
      @onReset="onReset"
      @onClose="filtered"
    >
      <template #body>
        <GeneralFilterRed
          v-for="item in filterData"
          @onFilter="onToggleFilterStatus($event)"
          :key="item.title"
          :filterList="item.list"
          :title="item.title"
          :isRemote="isRemote"
          type="employment"
          :filterStatus="filterStatus"
        />
      </template>
    </FilterVue>
  </div>
</template>

<script>
import FilterVue from '../Filter.vue'
import GeneralFilterRed from '../FilterContent/GeneralFilterRed.vue'
import SearchFilter from '../FilterContent/SearchFilter.vue'
export default {
  components: {
    FilterVue,
    SearchFilter,
    GeneralFilterRed,
  },

  props: {
    filter: Boolean,
    open: Boolean,
    search: Boolean,
    lodaData: Boolean,
    filterStatus: {
      type: Number,
      default: null,
    },
    filterData: {
      type: Array,
      default: null,
    },
  },

  methods: {
    /**
     * Emit state for open/close filter
     */
    filtered() {
      this.$emit('onFiltered')
    },

    /**
     * Emit handle click for change filter by type filter
     * @param evt - listener for change status filter
     */
    onToggleFilterStatus(evt) {
      this.$emit('onToggleFilterStatus', evt)
    },

    /**
     * Emit reset data
     */
    onReset() {
      this.$emit('onReset')
    },

    /**
     * Emit handle click search user
     * @param evt - event listener for search input
     */
    searchIconClick(evt) {
      this.$emit('onSearch', evt)
    },
  },
}
</script>

<style></style>
