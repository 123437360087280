<template>
  <div
    :class="[
      pageMenu === 'people' || pageMenu === 'employment'
        ? 'filter-card-people'
        : 'filter-card-request',
    ]"
    v-if="open"
  >
    <h4 class="filter-title">Filter</h4>
    <div class="close-filter" @click="closeFilter">x</div>
    <div class="reset-filter" @click="resetFilter">Reset</div>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  props: {
    open: Boolean,
    pageMenu: String,
  },

  computed: {},

  methods: {
    resetFilter() {
      this.$emit('onReset')
    },
    closeFilter() {
      this.$emit('onClose')
    },
  },
}
</script>

<style></style>
